import { SVG } from './svg'

export const BookIcon = () => (
	<SVG
		width='28'
		height='28'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M28.9562 24.3187L24.8075 4.59366C24.7538 4.33572 24.6497 4.09091 24.5012 3.87331C24.3526 3.65572 24.1626 3.46962 23.9419 3.3257C23.7212 3.18179 23.4742 3.0829 23.2152 3.03471C22.9562 2.98652 22.6902 2.98999 22.4325 3.04491L16.5812 4.30241C16.0637 4.41574 15.6118 4.72883 15.3239 5.17359C15.036 5.61836 14.9353 6.15881 15.0437 6.67741L19.1925 26.4024C19.285 26.8524 19.5295 27.2569 19.8851 27.5478C20.2407 27.8388 20.6856 27.9984 21.145 27.9999C21.287 27.9997 21.4286 27.9846 21.5675 27.9549L27.4188 26.6974C27.9369 26.5838 28.3893 26.2701 28.6772 25.8246C28.9652 25.379 29.0655 24.8378 28.9562 24.3187ZM17 6.26866C17 6.26116 17 6.25741 17 6.25741L22.85 5.00741L23.2663 6.99116L17.4163 8.24991L17 6.26866ZM17.8275 10.2024L23.68 8.94616L24.0975 10.9337L18.25 12.1912L17.8275 10.2024ZM18.6575 14.1487L24.51 12.8912L26.1725 20.7962L20.32 22.0537L18.6575 14.1487ZM27 24.7424L21.15 25.9924L20.7337 24.0087L26.5837 22.7499L27 24.7312C27 24.7387 27 24.7424 27 24.7424ZM13 3.99991H7C6.46957 3.99991 5.96086 4.21063 5.58579 4.5857C5.21071 4.96077 5 5.46948 5 5.99991V25.9999C5 26.5303 5.21071 27.0391 5.58579 27.4141C5.96086 27.7892 6.46957 27.9999 7 27.9999H13C13.5304 27.9999 14.0391 27.7892 14.4142 27.4141C14.7893 27.0391 15 26.5303 15 25.9999V5.99991C15 5.46948 14.7893 4.96077 14.4142 4.5857C14.0391 4.21063 13.5304 3.99991 13 3.99991ZM7 5.99991H13V7.99991H7V5.99991ZM7 9.99991H13V21.9999H7V9.99991ZM13 25.9999H7V23.9999H13V25.9999Z'
			// fill='black'
		/>
	</SVG>
)
