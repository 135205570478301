import React from 'react'
import { SVG } from './svg'

type Props = {}

export const Moon = (props: Props) => {
	return (
		<SVG
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g>
				<path
					d='M11.9709 22H11.0709C8.42153 21.7651 5.97958 20.4693 4.29991 18.407C2.60399 16.3478 1.79218 13.701 2.04191 11.045C2.43521 6.22905 6.2528 2.40342 11.0679 1.99999C11.4618 1.99793 11.8332 2.18361 12.0679 2.5C12.2873 2.84915 12.2464 3.30187 11.9679 3.606C11.0112 4.90556 10.615 6.53422 10.8679 8.128C11.0781 9.71686 11.9535 11.1416 13.2759 12.047C15.3767 13.6555 18.2951 13.6555 20.3959 12.047C20.5871 11.9145 20.8133 11.8414 21.0459 11.837C21.2037 11.8373 21.3592 11.8746 21.4999 11.946C21.8168 12.1825 22.0025 12.5556 21.9999 12.951C21.8009 15.6324 20.4925 18.1092 18.3899 19.785C16.544 21.1943 14.2931 21.971 11.9709 22ZM9.16291 4.311C6.22155 5.35162 4.17475 8.03424 3.94791 11.146C3.74749 13.2674 4.39661 15.3816 5.75291 17.025C7.09926 18.6691 9.05118 19.7015 11.1679 19.889C11.4299 19.914 11.6959 19.927 11.9579 19.927C13.8022 19.9417 15.5889 19.2852 16.9849 18.08C18.0689 17.168 18.8982 15.9908 19.3919 14.663C18.5222 14.9771 17.6046 15.1375 16.6799 15.137C14.9493 15.1366 13.264 14.5834 11.8699 13.558C10.1857 12.2776 9.04137 10.4134 8.66191 8.332C8.57163 6.972 8.74167 5.60727 9.16291 4.311Z'
					// fill='#FFF'
				/>
			</g>
		</SVG>
	)
}
