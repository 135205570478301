import * as Dialog from '@radix-ui/react-dialog'
import { keyframes, styled } from './theme/stitches.config'

const Root = styled(Dialog.Root, {
	position: 'relative',
})

const overlayShow = keyframes({
	'0%': { opacity: 0 },
	'100%': { opacity: 1 },
})

const contentShow = keyframes({
	'0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.9)' },
	'100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const Trigger = styled(Dialog.Trigger, {})
const Portal = styled(Dialog.Portal, {})
const Overlay = styled(Dialog.Overlay, {
	backgroundColor: 'rgba(0,0,0,.5)',
	backdropFilter: 'blur(8px)',
	position: 'fixed',
	inset: 0,
	zIndex: 1000,
	animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
})
const Content = styled(Dialog.Content, {
	animation: `${contentShow} 400ms cubic-bezier(0.16, 1, 0.3, 1)`,
	background: `linear-gradient($background, $background) padding-box,
	linear-gradient(180deg, rgba(95, 39, 255, 1) 0%, rgba(39, 100, 255, 1) 100%)`,
	border: '2px solid transparent',
	borderRadius: '5px',
	boxShadow:
		'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	maxWidth: '450px',
	maxHeight: '85vh',
	padding: 25,
	zIndex: 1000,
	gradientBorder: '2px',
	'&:focus': { outline: 'none' },
})
const Title = styled(Dialog.Title, {})
const Description = styled(Dialog.Description, {})
const Close = styled(Dialog.Close, {
	position: 'absolute',
	top: '1rem',
	right: '1rem',
	background: 'none',
	opacity: 0.66,
	transition: 'opacity 0.2s ease-in-out',
	'&:hover': {
		opacity: 1,
	},
	'& svg': {
		width: '1.25rem',
		height: '1.25rem',
	},
})

const CloseIcon = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M26.0612 23.9387C26.343 24.2205 26.5013 24.6027 26.5013 25.0012C26.5013 25.3997 26.343 25.7819 26.0612 26.0637C25.7794 26.3455 25.3972 26.5038 24.9987 26.5038C24.6002 26.5038 24.218 26.3455 23.9362 26.0637L15.9999 18.125L8.0612 26.0612C7.7794 26.343 7.39721 26.5013 6.9987 26.5013C6.60018 26.5013 6.21799 26.343 5.9362 26.0612C5.6544 25.7794 5.49609 25.3972 5.49609 24.9987C5.49609 24.6002 5.6544 24.218 5.9362 23.9362L13.8749 16L5.9387 8.06122C5.6569 7.77943 5.49859 7.39724 5.49859 6.99872C5.49859 6.60021 5.6569 6.21802 5.9387 5.93622C6.22049 5.65443 6.60268 5.49612 7.0012 5.49612C7.39971 5.49612 7.7819 5.65443 8.0637 5.93622L15.9999 13.875L23.9387 5.93497C24.2205 5.65318 24.6027 5.49487 25.0012 5.49487C25.3997 5.49487 25.7819 5.65318 26.0637 5.93497C26.3455 6.21677 26.5038 6.59896 26.5038 6.99747C26.5038 7.39599 26.3455 7.77818 26.0637 8.05998L18.1249 16L26.0612 23.9387Z'
			fill='currentColor'
		/>
	</svg>
)

interface ModalProps {
	children: React.ReactNode
	trigger: React.ReactNode
	title: React.ReactNode
	description: React.ReactNode
}

export const Modal = ({
	children,
	trigger,
	title,
	description,
}: ModalProps) => (
	<Root>
		<Trigger>{trigger}</Trigger>
		<Portal>
			<Overlay />
			<Content>
				<Title>{title}</Title>
				<Description>{description}</Description>
				{children}
				<Close>
					<CloseIcon />
				</Close>
			</Content>
		</Portal>
	</Root>
)
