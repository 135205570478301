import React from 'react'
import { styled } from './theme/stitches.config'

export const Input = styled('input', {
	// Reset
	appearance: 'none',
	outline: 'none',
	background: '$inputBg',
	border: '2px solid transparent',
	borderRadius: '$default',
	fontSize: '1rem',
	lineheight: 1,
	color: '$text',
	padding: '.75rem 1rem',
	width: '100%',
	transition: 'all 0.2s ease-in-out',
	'&:focus, &:hover': {
		borderColor: '$text',
		border: '2px solid $bgInverse',
	},
	'&:hover': {},
	'&:disabled': {},
	'&:invalid': {},
	'&:valid': {},
	'&::placeholder': {
		color: '$text',
		opacity: 0.45,
	},
})
