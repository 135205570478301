import { SessionProvider } from 'next-auth/react'

import { globalStyles, ToastProvider, ToastViewport } from 'ui'
import { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Inter } from '@next/font/google'
import localFont from '@next/font/local'
import { Provider } from 'jotai'
import { trpc } from '../utils/trpc'
import { Auth, MetaTags, MetaTagsProps } from '@components/layout/helpers'
export { reportWebVitals } from 'next-axiom'

const fontCalifornia = localFont({
	src: './CaliforniaCoast-Regular.woff2',
	variable: '--font-california-coast',
})
const fontInter = Inter({ subsets: ['latin'], variable: '--font-inter' })

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout & {
		auth?: boolean
		meta?: MetaTagsProps
	}
}

function MyApp({
	Component,
	pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
	// const [supabase] = useState(() => createBrowserSupabaseClient())
	globalStyles()

	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout || ((page) => page)

	return (
		<ToastProvider>
			<Provider>
				<SessionProvider session={session}>
					<style jsx global>{`
						:root {
							--font-california-coast: ${fontCalifornia.style.fontFamily};
							--font-inter: ${fontInter.style.fontFamily};
						}
					`}</style>
					<MetaTags {...pageProps.meta} />
					{Component.auth ? (
						<Auth>{getLayout(<Component {...pageProps} />)}</Auth>
					) : (
						getLayout(<Component {...pageProps} />)
					)}
				</SessionProvider>
			</Provider>
			<ToastViewport />
		</ToastProvider>
	)
}

export default trpc.withTRPC(MyApp)
