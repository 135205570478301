import { globalCss } from './stitches.config';

export const globalStyles = globalCss({
  '*': { margin: 0, padding: 0, boxSizing: 'border-box' },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  'body, h1, h2, h3, h4, h5, h6, p, ol, ul': {
    margin: 0,
    padding: 0,
    fontWeight: 'normal',
    WebkitFontSmoothing: 'antialiased',
  },
  'ol': {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  'ul': {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  'img': {
    maxWidth: '100%',
    height: 'auto',
  },
  'html': {
    boxSizing: 'border-box',
    fontSize: '16px',
    fontFamily: '$text',
    color: '$text',
    webkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    caretColor: '$accent'
  },
  'h1, h2, h3': {
    fontFamily: '$display',
    letterSpacing: '.025em',
    webkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    textRendering: 'optimizeLegibility'
  },
  'button': { cursor: 'pointer', border: 'none', outline: 'none' },
  'body': {
    background: '$background',
    color: '$text',
    margin: 0,
    padding: 0,
    fontWeight: 'normal',
  },
  'svg': { color: '$text' },
  'a': { color: 'inherit', textDecoration: 'none' },
});