import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { styled } from './theme/stitches.config'

export const MenuItem = styled(DropdownMenu.Item, {})

export const MenuContent = styled(DropdownMenu.Content, {
	background: '$colors$text',
	color: '$colors$background',
	borderRadius: '$default',
	padding: '1rem',
})

const DropdownMenuArrow = styled(DropdownMenu.Arrow, { fill: 'white' })

interface MenuProps {
	trigger: React.ReactNode
	children: React.ReactNode
}

export function Menu({ trigger, children }: MenuProps) {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<MenuContent>
					{children}
					<DropdownMenuArrow />
				</MenuContent>
			</DropdownMenu.Portal>

			{/* <DropdownMenu.Portal>
				<DropdownMenu.Content>
					<DropdownMenu.Label>test</DropdownMenu.Label>
					<DropdownMenu.Item>Account</DropdownMenu.Item>

					<DropdownMenu.Group>
						<DropdownMenu.Item />
					</DropdownMenu.Group>

					<DropdownMenu.CheckboxItem>
						<DropdownMenu.ItemIndicator />
					</DropdownMenu.CheckboxItem>

					<DropdownMenu.RadioGroup>
						<DropdownMenu.RadioItem>
							<DropdownMenu.ItemIndicator />
						</DropdownMenu.RadioItem>
					</DropdownMenu.RadioGroup>

					<DropdownMenu.Sub>
						<DropdownMenu.SubTrigger />
						<DropdownMenu.Portal>
							<DropdownMenu.SubContent />
						</DropdownMenu.Portal>
					</DropdownMenu.Sub>

					<DropdownMenu.Separator />
					<DropdownMenu.Arrow />
				</DropdownMenu.Content>
			</DropdownMenu.Portal> */}
		</DropdownMenu.Root>
	)
}
