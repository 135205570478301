import { styled } from './theme/stitches.config'

export const Flex = styled('div', {
	display: 'flex',
	variants: {
		align: {
			center: {
				alignItems: 'center',
			},
			start: {
				alignItems: 'flex-start',
			},
			end: {
				alignItems: 'flex-end',
			},
		},
		justify: {
			center: {
				justifyContent: 'center',
			},
			start: {
				justifyContent: 'flex-start',
			},
			end: {
				justifyContent: 'flex-end',
			},
		},
		direction: {
			row: {
				flexDirection: 'row',
			},
			column: {
				flexDirection: 'column',
			},
		},
	},
})
