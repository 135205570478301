import { signIn, useSession } from 'next-auth/react'
import Head from 'next/head'
import { ReactNode, useEffect } from 'react'

export interface MetaTagsProps {
	title?: string
	description?: string
	// Add more meta properties as needed
}

export function MetaTags({
	title = 'Boundless Stories, Infinite Possibilities',
	description = `Discover the world of boundless stories
  and infinite possibilities with our AI-powered storytelling app.
  Unleash your creativity as our intelligent algorithms craft
  unique and captivating narratives tailored to your imagination.
  Whether you're a writer seeking inspiration or a dreamer
  exploring new worlds, our app empowers you to become the
  master of your own storytelling journey. Experience the
  magic of AI-generated tales and let your imagination soar!`,
}: MetaTagsProps) {
	return (
		<Head>
			{title && <title>Talltale – {title}</title>}
			{description && <meta name='description' content={description} />}
		</Head>
	)
}

export function Auth({ children }: { children: ReactNode }) {
	const { data: session, status } = useSession()

	const isUser = !!session?.user

	useEffect(() => {
		if (status === 'loading') return // Do nothing while loading
		if (!isUser) signIn() // If not authenticated, force log in
	}, [isUser, status])

	if (isUser) {
		return <>{children}</>
	}
	// Session is being fetched, or no user.
	// If no user, useEffect() will redirect.
	return <div>Loading...</div>
}
