import { SVG } from './svg'

export const LeaningBook = () => {
	return (
		<SVG
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.725 15.6834L17.8917 12.4667L15.2667 2.80838C15.2099 2.59533 15.0711 2.41344 14.8805 2.30255C14.69 2.19165 14.4633 2.16079 14.25 2.21671L11.025 3.05005C10.948 2.96475 10.8543 2.89629 10.7496 2.84896C10.6449 2.80164 10.5315 2.77647 10.4167 2.77505H2.08333C1.86232 2.77505 1.65036 2.86284 1.49408 3.01912C1.3378 3.1754 1.25 3.38736 1.25 3.60838V16.9417C1.25 17.1627 1.3378 17.3747 1.49408 17.531C1.65036 17.6872 1.86232 17.775 2.08333 17.775H10.4167C10.6377 17.775 10.8496 17.6872 11.0059 17.531C11.1622 17.3747 11.25 17.1627 11.25 16.9417V10.275L13.0833 17.125C13.1322 17.3068 13.2411 17.4668 13.3924 17.5787C13.5438 17.6907 13.7285 17.7482 13.9167 17.7417C13.9887 17.7499 14.0613 17.7499 14.1333 17.7417L18.1583 16.6667C18.2647 16.6383 18.3645 16.589 18.4517 16.5218C18.539 16.4545 18.612 16.3707 18.6667 16.275C18.7546 16.0907 18.7752 15.8814 18.725 15.6834ZM5.39167 16.1417H2.89167V14.475H5.39167V16.1417ZM5.39167 12.8084H2.89167V7.80838H5.39167V12.8084ZM5.39167 6.14171H2.89167V4.47505H5.39167V6.14171ZM9.55833 16.1417H7.05833V14.475H9.55833V16.1417ZM9.55833 12.8084H7.05833V7.80838H9.55833V12.8084ZM9.55833 6.14171H7.05833V4.47505H9.55833V6.14171ZM11.4333 4.69171L13.85 4.04171L14.2833 5.65005L11.8667 6.30005L11.4333 4.69171ZM13.5917 12.7417L12.3 7.90838L14.7167 7.25838L16.0083 12.0917L13.5917 12.7417ZM14.425 15.9584L13.9917 14.35L16.4083 13.7L16.8417 15.3084L14.425 15.9584Z'
				fill='currentColor'
			/>
		</SVG>
	)
}
