import { styled } from './theme/stitches.config'

export const TextPage = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '800px',
	margin: '0 auto',
	padding: '6rem 1rem',
	'& h1': {
		mb: '2rem',
	},
	'& h2': {
		mb: '.75rem',
		mt: '.75rem',
	},
	'& p': {
		mb: '.75rem',
		lineHeight: '1.33',
		opacity: 0.8,
	},
	'& ul, & ol': {
		mb: '.75rem',
		paddingLeft: '1rem',
	},
	'& li': {
		mb: '.25rem',
		listStyleType: 'disc',
		opacity: 0.8,
	},
})
