import * as A from '@radix-ui/react-avatar'
import { styled } from './theme/stitches.config'

const sharedStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '2rem',
	height: '2rem',
	minHeight: '2rem',
	minWidth: '2rem',
	fontSize: '.875rem',
	background: '$bgInverseDark',
	color: '$background',
	borderRadius: '50%',
}

const Image = styled(A.Image, {
	...sharedStyle,
})

const Fallback = styled(A.Fallback, {
	...sharedStyle,
})

interface Props {
	fallback: React.ReactNode
	src?: string
	alt?: string
}

export const Avatar = ({ fallback, src, alt }: Props) => (
	<A.Root>
		<Image src={src} alt={alt} />
		<Fallback>{fallback}</Fallback>
	</A.Root>
)
