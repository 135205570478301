import * as React from 'react'
import { styled } from './theme/stitches.config'

export const Button = styled('button', {
	boxShadow: '0px 2px 160px rgba(7, 15, 36, 0.06)',
	borderRadius: '$default',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '1.25rem',
	lineHeight: '1',
	padding: '.75rem 1.25rem',
	background: 'linear-gradient(134.95deg, #2764FF 25.02%, #5F27FF 100%)',
	color: '$white',
	fontFamily: '$display',
	cursor: 'pointer',
	transition: 'all 0.2s ease-in-out',
	'&:hover': {
		transform: 'scale(1.025)',
	},
	variants: {
		outline: {
			true: {
				background: `linear-gradient($background, $background) padding-box,
	linear-gradient(180deg, rgba(95, 39, 255, 0.25) 0%, rgba(39, 100, 255, 0.25) 100%)`,
				border: '2px solid transparent',
				'&:hover': {
					background: `linear-gradient($background, $background) padding-box,
		linear-gradient(180deg, rgba(95, 39, 255, 0.5) 0%, rgba(39, 100, 255, 0.5) 100%)`,
				},
			},
		},
	},
})

export const TransparentButton = styled(Button, {
	background: 'transparent',
	border: 'none',
	'&:hover': {
		background: '$bgInverse',
	},
})

// export const Button = () => {
//   return <button>Boop</button>;
// };
