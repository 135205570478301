import * as ToastPrimitive from '@radix-ui/react-toast'
import { ReactNode } from 'react'
import { styled, keyframes } from './theme/stitches.config'

interface ToastProps extends ToastPrimitive.ToastProps {
	title?: string
	content?: ReactNode
	children?: ReactNode
	altText: string
}

export const ToastProvider = ToastPrimitive.Provider

const VIEWPORT_PADDING = 25

export const ToastViewport = styled(ToastPrimitive.Viewport, {
	position: 'fixed',
	bottom: 0,
	right: 0,
	display: 'flex',
	flexDirection: 'column',
	padding: VIEWPORT_PADDING,
	gap: 10,
	width: 390,
	maxWidth: '100vw',
	margin: 0,
	listStyle: 'none',
	zIndex: 2147483647,
	outline: 'none',
})

const hide = keyframes({
	'0%': { opacity: 1 },
	'100%': { opacity: 0 },
})

const slideIn = keyframes({
	from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
	to: { transform: 'translateX(0)' },
})

const swipeOut = keyframes({
	from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
	to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
})

const ToastRoot = styled(ToastPrimitive.Root, {
	backgroundColor: 'white',
	borderRadius: 6,
	boxShadow:
		'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
	padding: 15,
	display: 'grid',
	gridTemplateAreas: '"title action" "description action"',
	gridTemplateColumns: 'auto max-content',
	columnGap: 15,
	alignItems: 'center',

	'&[data-state="open"]': {
		animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	},
	'&[data-state="closed"]': {
		animation: `${hide} 100ms ease-in`,
	},
	'&[data-swipe="move"]': {
		transform: 'translateX(var(--radix-toast-swipe-move-x))',
	},
	'&[data-swipe="cancel"]': {
		transform: 'translateX(0)',
		transition: 'transform 200ms ease-out',
	},
	'&[data-swipe="end"]': {
		animation: `${swipeOut} 100ms ease-out`,
	},
})

const ToastTitle = styled(ToastPrimitive.Title, {
	gridArea: 'title',
	marginBottom: 5,
	fontWeight: 500,
	color: '$background',
	fontSize: 15,
})

const ToastDescription = styled(ToastPrimitive.Description, {
	gridArea: 'description',
	margin: 0,
	color: '$background',
	fontSize: 13,
	lineHeight: 1.3,
})

const ToastAction = styled(ToastPrimitive.Action, {
	gridArea: 'action',
})

export const Toast = ({
	title,
	content,
	duration = 5000,
	children,
	...props
}: ToastProps) => {
	return (
		<ToastRoot {...props}>
			{title && <ToastTitle>{title}</ToastTitle>}
			<ToastDescription>{content}</ToastDescription>
			{children && (
				<ToastAction altText={props.altText} asChild>
					{children}
				</ToastAction>
			)}
			<ToastPrimitive.Close aria-label='Close'>
				<span aria-hidden>×</span>
			</ToastPrimitive.Close>
		</ToastRoot>
	)
}
