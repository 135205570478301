import React from 'react'
import { SVG } from './svg'

export const MenuIcon = () => {
	return (
		// <SVG
		// 	width='24'
		// 	height='24'
		// 	viewBox='0 0 24 24'
		// 	fill='none'
		// 	xmlns='http://www.w3.org/2000/svg'>
		// 	<path d='M21 16H3V14H21V16ZM21 10H3V8H21V10Z' />
		// </SVG>
		<SVG
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3 18H21'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3 12H21'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3 6H21'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</SVG>
	)
}
