import { createStitches } from '@stitches/react';

type UtilityFunction = (value: string | number) => Record<string, string | number>;

type FlexFunction = () => Record<string, string>;
type TruncateFunction = () => Record<string, string>;


export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  utils: {
  mX: ((value: string | number) => ({
    marginLeft: value,
    marginRight: value,
  })) as UtilityFunction,

  mY: ((value: string | number) => ({
    marginTop: value,
    marginBottom: value,
  })) as UtilityFunction,

  pX: ((value: string | number) => ({
    paddingLeft: value,
    paddingRight: value,
  })) as UtilityFunction,

  pY: ((value: string | number) => ({
    paddingTop: value,
    paddingBottom: value,
  })) as UtilityFunction,

  size: ((value: string | number) => ({
    width: value,
    height: value,
  })) as UtilityFunction,

  radius: ((value: string | number) => ({
    borderRadius: value,
  })) as UtilityFunction,

  shadow: ((value: string | number) => ({
    boxShadow: value,
  })) as UtilityFunction,

  flexCenter: (() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })) as FlexFunction,

  flexColumn: (() => ({
    display: 'flex',
    flexDirection: 'column',
  })) as FlexFunction,

  flexRow: (() => ({
    display: 'flex',
    flexDirection: 'row',
  })) as FlexFunction,

  flex: (() => ({
    display: 'flex',
  })) as FlexFunction,

  grid: (() => ({
    display: 'grid',
  })) as FlexFunction,

  mt: ((value: string | number) => ({
    marginTop: value,
  })) as UtilityFunction,

  mr: ((value: string | number) => ({
    marginRight: value,
  })) as UtilityFunction,

  mb: ((value: string | number) => ({
    marginBottom: value,
  })) as UtilityFunction,

  ml: ((value: string | number) => ({
    marginLeft: value,
    })) as UtilityFunction,

  truncate: () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    }),
  },
  media: {
      'bp640': '(min-width: 640px)',
      'bp768': '(min-width: 768px)',
      'bp1080': '(min-width: 1080px)',
      'bp1180': '(min-width: 1180px)',
  },
  theme: {
    colors: {
      // Primitives
      white: '#fff',
      black: '#050D22',
      gray100: '#F7F9FC',
      gray200: '#E9EDF6',
      gray300: '#B3BDD7',
      gray400: '#707DA0',
      gray500: '#4E5A78',
      gray600: '#2E3A59',
      gray700: '#1E293B',
      gray800: '#0F172A',
      gray900: '#050D22',

      // Tokens
      background: '#fff',
      bgInverse: '#DEE7FF',
      bgInverseDark: '#B3BDD7',
      inputBg: '$bgInverse',
      text: '#050D22',
      accent: "#5F27FF",
      transparentGradient: 'linear-gradient(134.95deg, rgba(39, 100, 255, 0.05) 25.02%, rgba(95, 39, 255, 0.05) 100%)',
    },
    space: {
      '025': '.25rem',
      '050': '.5rem',
      '075': '.75rem',
      '0875': '.875rem',
      '100': '1rem',
      '125': '1.25rem',
      '150': '1.5rem',
      '175': '1.75rem',
      '200': '2rem',
      '250': '2.5rem',
      '300': '3rem',
      '350': '3.5rem',
      '400': '4rem',
      '450': '4.5rem',
      '500': '5rem',
    },
    fontSizes: {
      '0875': '.875rem',
      '100': '1rem',
      '125': '1.25rem',
      '150': '1.5rem',
      '175': '1.75rem',
      '200': '2rem',
      '250': '2.5rem',
      '300': '3rem',
      '350': '3.5rem',
      '400': '4rem',
    },
    fonts: {
      text: "var(--font-inter)",
      display: "var(--font-california-coast)",
    },
    fontWeights: {},
    lineHeights: {},
    letterSpacings: {},
    sizes: {
      // Tokens
      maxWidth: '1600px',
      wide: '1600px',
      default: '1280px',
      narrow: '960px',
      fluid: '100%',
    },
    borderWidths: {},
    borderStyles: {
      gradient: `linear-gradient($background, $background) padding-box,
	               linear-gradient(180deg, rgba(95, 39, 255, 0.25) 0%, rgba(39, 100, 255, 0.25) 100%)`,
    },
    radii: {
      default: '10px',
      image: '10px',
      rounded: '50%',
    },
    shadows: {},
    zIndices: {},
    transitions: {},
    utils: {
      //@ts-ignore
      gradientBorder: (value = '2px') => ({
        background: `linear-gradient($background, $background) padding-box,
        linear-gradient(180deg, rgba(95, 39, 255, 0.25) 0%, rgba(39, 100, 255, 0.25) 100%)`,
        border: `${value} solid transparent`,
        borderRadius: '$default',
      })
    },
  },
});


export const darkTheme = createTheme({
  colors: {
    background: '#050D22',
    text: '#fff',
    bgInverse: '#707DA0',
    bgInverseDark: '#B3BDD7',
    inputBg: '$background',
    accent: "#5F27FF"
  },
  space: {},
  fonts: {},
});