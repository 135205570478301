import { styled } from './theme/stitches.config'

export const Text = styled('p', {
	// Reset
	lineHeight: '1',
	margin: '0',
	fontWeight: 400,
	fontVariantNumeric: 'tabular-nums',
	display: 'block',
	// Defaults
	'& a': {
		borderBottom: '1px solid currentColor',
		'&:hover': {
			color: '$text',
		},
	},
	optimizeLegibility: 'auto',
	variants: {
		font: {
			display: {
				fontFamily: '$display',
			},
			text: {
				fontFamily: '$text',
			},
		},
	},
	defaultVariants: {
		font: 'text',
	},
})
