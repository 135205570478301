import { AnimatePresence, motion, wrap } from 'framer-motion'
import { styled } from './theme/stitches.config'
import { useState } from 'react'
import { Flex } from './Flex'

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
	return Math.abs(offset) * velocity
}

interface CardWrapProps {
	children: React.ReactNode
	direction: number
	variant: string
	paginate: (newDirection: number) => void
}

const CardWrap = ({
	children,
	direction,
	variant,
	paginate,
}: CardWrapProps) => (
	<motion.div
		custom={direction}
		variants={{
			prev: { x: 0, opacity: 0.5, scale: 0.9, rotateY: 20 },
			center: { x: 0, opacity: 1, scale: 1.05, rotateY: 0 },
			next: { x: 0, opacity: 0.5, scale: 0.9, rotateY: -20 },
		}}
		transition={{ duration: 0.33 }}
		initial={variant}
		animate={variant}
		drag='x'
		dragConstraints={{ left: 0, right: 0 }}
		dragElastic={1}
		onDragEnd={(e, { offset, velocity }) => {
			const swipe = swipePower(offset.x, velocity.x)
			if (swipe < -swipeConfidenceThreshold) paginate(1)
			else if (swipe > swipeConfidenceThreshold) paginate(-1)
		}}
		layout>
		{children}
	</motion.div>
)

const buttonstyles = {
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	background: 'linear-gradient(134.95deg, #2754FF 25.02%, #5F27FF 100%)',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	borderRadius: '50%',
	width: 50,
	height: 50,
	border: 'none',
	cursor: 'pointer',
	zIndex: 200,
	color: 'white',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: 0.8,
	transition: 'opacity .2s ease-in-out',
	'&:focus': {
		outline: 'none',
	},
	'&:hover': {
		opacity: 1,
	},
}

const PrevButton = styled('button', {
	left: 0,
	...buttonstyles,
})

const NextButton = styled('button', {
	right: 0,
	...buttonstyles,
})

const PrevIcon = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M20.7073 25.2926C20.8002 25.3855 20.8739 25.4958 20.9242 25.6172C20.9745 25.7386 21.0004 25.8687 21.0004 26.0001C21.0004 26.1315 20.9745 26.2616 20.9242 26.383C20.8739 26.5044 20.8002 26.6147 20.7073 26.7076C20.6144 26.8005 20.5041 26.8742 20.3827 26.9245C20.2613 26.9747 20.1312 27.0006 19.9998 27.0006C19.8684 27.0006 19.7383 26.9747 19.6169 26.9245C19.4955 26.8742 19.3852 26.8005 19.2923 26.7076L9.29231 16.7076C9.19933 16.6147 9.12557 16.5044 9.07525 16.383C9.02493 16.2616 8.99902 16.1315 8.99902 16.0001C8.99902 15.8687 9.02493 15.7385 9.07525 15.6171C9.12557 15.4957 9.19933 15.3854 9.29231 15.2926L19.2923 5.29257C19.4799 5.10493 19.7344 4.99951 19.9998 4.99951C20.2652 4.99951 20.5197 5.10493 20.7073 5.29257C20.895 5.48021 21.0004 5.7347 21.0004 6.00007C21.0004 6.26543 20.895 6.51993 20.7073 6.70757L11.4136 16.0001L20.7073 25.2926Z'
			fill='white'
		/>
	</svg>
)
const NextIcon = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M22.7071 16.7076L12.7071 26.7076C12.6142 26.8005 12.5039 26.8742 12.3825 26.9245C12.2611 26.9747 12.131 27.0006 11.9996 27.0006C11.8682 27.0006 11.7381 26.9747 11.6167 26.9245C11.4953 26.8742 11.385 26.8005 11.2921 26.7076C11.1992 26.6147 11.1255 26.5044 11.0752 26.383C11.0249 26.2616 10.999 26.1315 10.999 26.0001C10.999 25.8687 11.0249 25.7386 11.0752 25.6172C11.1255 25.4958 11.1992 25.3855 11.2921 25.2926L20.5858 16.0001L11.2921 6.70757C11.1044 6.51993 10.999 6.26543 10.999 6.00007C10.999 5.7347 11.1044 5.48021 11.2921 5.29257C11.4797 5.10493 11.7342 4.99951 11.9996 4.99951C12.2649 4.99951 12.5194 5.10493 12.7071 5.29257L22.7071 15.2926C22.8001 15.3854 22.8738 15.4957 22.9241 15.6171C22.9745 15.7385 23.0004 15.8687 23.0004 16.0001C23.0004 16.1315 22.9745 16.2616 22.9241 16.383C22.8738 16.5044 22.8001 16.6147 22.7071 16.7076Z'
			fill='white'
		/>
	</svg>
)

export const Slider = ({ items }: { items: React.ReactNode[] }) => {
	const [[page, direction], setPage] = useState([0, 0])
	const itemIndex = wrap(0, items.length, page)
	const prevIndex = wrap(0, items.length, page - 1)
	const nextIndex = wrap(0, items.length, page + 1)

	const paginate = (newDirection: number) => {
		setPage([page + newDirection, newDirection])
	}

	return (
		<>
			<PrevButton onClick={() => paginate(-1)}>
				<PrevIcon />
			</PrevButton>
			<Flex
				style={{
					width: '100%',
					height: '100%',
					minHeight: 'max-content',
					gap: '2rem',
				}}>
				<AnimatePresence initial={false} custom={direction}>
					<CardWrap
						direction={direction}
						variant='prev'
						key={prevIndex}
						paginate={paginate}>
						{items[prevIndex]}
					</CardWrap>
					<CardWrap
						direction={direction}
						variant='center'
						key={itemIndex}
						paginate={paginate}>
						{items[itemIndex]}
					</CardWrap>
					<CardWrap
						direction={direction}
						variant='next'
						key={nextIndex}
						paginate={paginate}>
						{items[nextIndex]}
					</CardWrap>
				</AnimatePresence>
			</Flex>
			<NextButton onClick={() => paginate(1)}>
				<NextIcon />
			</NextButton>
		</>
	)
}
