import { styled, darkTheme } from './theme/stitches.config'

export const FancyBox = styled('div', {
	position: 'relative',
	// boxShadow: '0px 2px 160px rgba(7, 15, 36, 0.075)',
	background: `linear-gradient($background, $background) padding-box,
        linear-gradient(180deg, rgba(95, 39, 255, 0.25) 0%, rgba(39, 100, 255, 0.25) 100%)`,
	border: '2px solid transparent',
	borderRadius: '$default',
	padding: '3rem',
	[`.${darkTheme} &`]: {
		// boxShadow: '0px 2px 160px rgba(255, 255, 255, 0.05)',
	},
})
